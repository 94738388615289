<template>
  <div class="article-box" v-loading="loading">
    <div class="page-nav clearfix">
            <ul>
                <li class = "nav-item">
                    会员管理&nbsp;&nbsp;/
                </li>
                <li class = "nav-item nav-item-last">
                    &nbsp;&nbsp;消息发送
                </li>
            </ul>
        </div>
    
    <!-- 标题 -->
    <div class="pag-title clearfix" style="margin-top: 25px;">
        <div class="page-title-name pull-left">消息发送</div>
        <div class="page-title-button pull-right" @click="addMsg">添加消息</div>
    </div>
    <!-- 表格 -->
    <el-table border :data="listData" highlight-current-row header-row-class-name="table-header-class" :header-cell-style="{ 'background-color': '#F7F8FA' }"
    style="width: 100%; margin-top: 20px;" >
      <el-table-column type="index" label="序号" width="50"></el-table-column>
      <el-table-column prop="title" label="标题" ></el-table-column>
      <el-table-column prop="createDate" label="创建时间" width="180"></el-table-column>
      <el-table-column prop="sendDate" label="发送时间" width="180"></el-table-column>
      <el-table-column prop="" label="消息状态" width="100">
        <template slot-scope="scope" >
          <span v-if="scope.row.status == 1">已发送</span>
          <span v-if="scope.row.status == 2">未发送</span>
        </template>
      </el-table-column>
      <el-table-column prop="" label="操作" width="120">
        <template slot-scope="scope" >
          <div class="clecrfix">
            <div class="option-item option-normal" @click="toDetail(scope.row.id)">查看详情</div>
          </div>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      style="text-align: right;padding-top: 20px;"
      @size-change="pageSizeHandle"
      @current-change="pageChangeHandle"
      :page-sizes="[10, 20, 30, 40, 50, 60, 70, 80]"
      layout="total, sizes, prev, pager, next, jumper"
      :page-size="pageSize"
      :current-page="pageNo"
      :page-count="totalPage"
      :total="totalCount">
    </el-pagination>
    <!-- <div class="table-box" v-if="totalCount">
      <table>
          <thead>
              <tr>
                  <th style="width: 30%;text-align: left;padding-left: 25px">标题</th>
                  <th style="width: 15%;text-align: center;">创建时间</th>
                  <th style="width: 15%;text-align: center;">发送时间</th>
                  <th style="width: 20%;text-align: center;">消息状态</th>
                  <th style="width: 20%;text-align: center;">操作</th>
              </tr>
          </thead>
          <tbody>
              <tr v-for="(item) in listData" :key="item.id" style="cursor: pointer;">
                  <td style="width: 30%;text-align: left;padding-left: 25px;position: relative">
                    <span>{{ item.title || '-' }}</span>
                  </td>
                  <td style="width: 15%;text-align: center;">{{ item.createDate || '-'}}</td>
                  <td style="width: 15%;text-align: center;">{{ item.sendDate || '-'}}</td>
                  <td style="width: 20%;text-align: center;">{{ item.status == 1 ? '已发送' : '未发送' }}</td>
                  <td  style="width: 20%;text-align: center;">
                      <div class="option-item option-normal" @click="toDetail(item.id)">查看详情</div>
                  </td>
              </tr>
          </tbody>
      </table>
      <el-pagination
          style="text-align: right;padding-top: 20px;"
          @size-change="pageSizeHandle"
          @current-change="pageChangeHandle"
          :page-sizes="[10, 20, 30, 40, 50, 60, 70, 80]"
          layout="total, sizes, prev, pager, next, jumper"
          :page-size="pageSize"
          :current-page="pageNo"
          :page-count="totalPage"
          :total="totalCount">  
      </el-pagination>
    </div>
    <div class="no-data-img"  v-if="noData"></div> -->
    <!-- 详情 -->
    <el-dialog title="消息详情" :visible.sync="isShowDetail" >
       <el-descriptions :column="1" border>
        <el-descriptions-item>
          <template slot="label" :contentStyle="{'width': '300;text-align:center'}">
            消息标题
          </template>
          {{this.msgDetail.title}}
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label" :contentStyle="{'width': '300;text-align:center'}">
            创建时间
          </template>
          {{this.msgDetail.createDate}}
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label" :contentStyle="{'width': '300;text-align:center'}">
            发送时间
          </template>
          {{this.msgDetail.sendDate}}
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label" :contentStyle="{'width': '300;text-align:center'}">
            推送人员
          </template>
          <el-tag style="margin-right:10px" size="small" v-for="(u, i) in this.msgDetail.userList" :key="'user' + i">{{u}}</el-tag>
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label" :contentStyle="{'width': '300;text-align:center'}">
            消息内容
          </template>
          {{this.msgDetail.content}}
        </el-descriptions-item>
       </el-descriptions>
    </el-dialog>
  </div>
</template>
<script>
import { MermberMsgList, MermberMsgDetail } from "@/http/api/InitiationApi";

export default {
  name: "",
  props: {},
  components: {},
  data() {
    return {
      loading: false,
      listData: [],
      keyword: '',
      noData: false,
      totalPage: 0,
      totalCount: 0,
      pageNo: 1,
      pageSize: 10,
      isShowDetail: false,
      msgDetail: {
        content: '',
        createDate: '',
        sendDate: '',
        title: '',
        userList: []
      }
    };
  },
  computed: {},
  created() {
    this.getList();
  },
  methods: {
    getList() {
      this.loading = true
      MermberMsgList({
        pageNo: this.pageNo || 1,
        pageSize: this.pageSize || 10,
      }).then(resp => {
        this.loading = false
        if (resp.code === "000") {
          this.totalPage = resp.result.pages;
          this.totalCount = resp.result.total;
          this.pageNo = resp.result.current
          this.listData = resp.result.records;
          if (!resp.result.total) {
              this.noData = true
          }
        } else {
          this.noData = true
        }
      });
    },
    pageSizeHandle (val) {
        this.pageNo = 1;
        this.pageSize = val;
        this.getList();
    },
    pageChangeHandle (val) {
        this.pageNo = val;
        this.getList();
    },
    toDetail (id) {
      this.loading = true
      MermberMsgDetail({id: id}).then(resp => {
        this.loading = false;
        if (resp.code == '000') {
          this.isShowDetail = true;
          this.msgDetail.content = resp.result.content || '';
          this.msgDetail.createDate = resp.result.createDate || '';
          this.msgDetail.sendDate = resp.result.sendDate || '';
          this.msgDetail.title = resp.result.title || '';
          this.msgDetail.userList = resp.result.userList || '';
        } else {
        }
      })

    },
    addMsg () {
      this.$router.push({path: '/institution/member/msg/add'})
    }
  },
};
</script>
<style lang='less' scoped>
// .no-data-img {
//     width: 100%;
//     height: 300px;
//     background: url("../../assets/images/nodata.png") no-repeat center center;
// }
// ul li {
//   width: 90%;
//   height: 50px;
//   line-height: 50px;
//   border: 1px solid #ccc;
//   background-color: #eee;
//   margin: 10px;
//   display: flex;
//   justify-content: space-between;
// }
// ul li span {
//   width: 35%;
//   text-align: center;
// }
// .add {
//   width: 100%;
//   text-align: center;
// }
// .red {
//   color: red;
//   border: 5px red solid;
//   border-radius: 5px;
//   width: 5px;
//   height: 5px;
// }

// .hot-dot {
//   background-color: #C30000 ;
//   border-radius: 5px;
//   width: 10px;
//   height: 10px;
//   position:absolute;
//   top: 24px;
//   left: 8px;
// }
</style>